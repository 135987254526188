<template>
  <div class="container">
    <h1 class="termsTitle">Application Form - Thanks</h1>
    <div class="termsContent">
      <p>
        Thanks! Your application form has been submitted. If you applied for our
        summer or full-time positions, we will get in touch soon to schedule
        your interview. If you applied to join us as an online Teaching Fellow,
        we will contact you if we see an opening that we think will suit you.
        Feel free to contact us in the mean time if you have any questions.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: "Application Form - Thanks - Ivy-Way Academy"
    };
  }
};
</script>

<style scoped>
.termsTitle {
  margin: 20px 0px;
}

.termsContent {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  text-align: left;
  overflow: hidden;
  margin-bottom: 30px;
}
</style>
